import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  format,
  parse,
} from 'date-fns';

import './style.css';

function Report2({ reportData, setSelectedEvaluation }) {
  const columnsList01 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '22%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'evaluationValue', label: 'VALOR DO CÁLCULO', minWidth: 75, width: '8%', align: 'right',
    },
    {
      id: 'distributedAt', label: 'DATA DISTRIBUIÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'caseNumber', label: 'Nº PROCESSO', minWidth: 50, width: '8%', align: 'center',
    },
    {
      id: 'amountToReceive', label: 'HONORÁRIOS PREVISTOS', minWidth: 50, width: '8%', align: 'right',
    },
  ];

  const columnsList02 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '22%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'evaluationValue', label: 'VALOR DO CÁLCULO', minWidth: 75, width: '8%', align: 'right',
    },
    {
      id: 'distributedAt', label: 'DATA DISTRIBUIÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'caseNumber', label: 'Nº PROCESSO', minWidth: 50, width: '8%', align: 'center',
    },
    {
      id: 'paymentReceivedAt', label: 'DATA RECEBIMENTO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'amountReceived', label: 'HONORÁRIOS RECEBIDOS', minWidth: 50, width: '8%', align: 'right',
    },
  ];

  return (
    <>
      <Paper sx={{
        width: '100%', height: '60vh', overflowY: 'scroll', paddingTop: '4px',
      }}
      >
        <header>
          <h3 className='report2-title'>
            {reportData.reportTitle}
          </h3>
        </header>
        <div className='report2-summary'>
          <div className='report2-summary-column'>
            <div>
              <span>
                <strong>Período: </strong>
                {(reportData.startDate && reportData.endDate) ? `${format(parse(reportData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(reportData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Produto: </strong>
                {reportData.product ? reportData.product : 'Geral'}
              </span>
            </div>
          </div>
          <div className='report2-summary-column'>
            <div>
              <span>
                <strong>Origem: </strong>
                {reportData.origin ? reportData.origin : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Parceiro: </strong>
                {reportData.partner ? reportData.partner : 'Geral'}
              </span>
            </div>
          </div>
          <div className='report2-summary-column'>
            <div>
              <span>
                <strong>Honorários previstos: </strong>
                {`R$ ${Number(reportData.amountToReceive) > 0
                  ? (`${Number(reportData.amountToReceive).toLocaleString()
                    + (Number(reportData.amountToReceive).toLocaleString().indexOf(',') === -1
                      ? ',00'
                      : ((Number(reportData.amountToReceive).toLocaleString().length - (Number(reportData.amountToReceive).toLocaleString().indexOf(',') + 1) === 1)
                        ? '0'
                        : ''))} (${reportData.casesToReceive.length} casos)`)
                  : '-'}`}
              </span>
            </div>
            <div>
              <span>
                <strong>Honorários recebidos: </strong>
                {`R$ ${Number(reportData.amountReceived) > 0
                  ? (`${Number(reportData.amountReceived).toLocaleString()
                    + (Number(reportData.amountReceived).toLocaleString().indexOf(',') === -1
                      ? ',00'
                      : ((Number(reportData.amountReceived).toLocaleString().length - (Number(reportData.amountReceived).toLocaleString().indexOf(',') + 1) === 1)
                        ? '0'
                        : ''))} (${reportData.casesReceived.length} casos)`)
                  : '-'}`}
              </span>
            </div>
          </div>
          <div className='report2-summary-column'>
            <div>
              <span>
                <strong>Valor total de cálculos: </strong>
                {`R$ ${Number(reportData.totalEvaluationValue) > 0
                  ? (Number(reportData.totalEvaluationValue).toLocaleString()
                    + (Number(reportData.totalEvaluationValue).toLocaleString().indexOf(',') === -1
                      ? ',00'
                      : ((Number(reportData.totalEvaluationValue).toLocaleString().length - (Number(reportData.totalEvaluationValue).toLocaleString().indexOf(',') + 1) === 1)
                        ? '0'
                        : '')))
                  : '-'}`}
              </span>
            </div>
            <div>
              <span><br /></span>
            </div>
          </div>
        </div>
        {reportData.casesToReceive.length > 0
          && (
            <>
              <h4 className='report2-subtitle'>
                <strong>CASOS DISTRIBUÍDOS COM RECEBIMENTOS PREVISTOS</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table className='table-body-fixed'>
                  <TableHead>
                    <TableRow>
                      {columnsList01.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.casesToReceive
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList01.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : ((column.id === 'evaluationValue' || column.id === 'amountToReceive')
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        R$
                                        {' '}
                                        {Number(value) > 0
                                          ? (Number(value).toLocaleString()
                                            + ((Number(value).toLocaleString().indexOf(',') === -1)
                                              ? ',00'
                                              : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1)
                                                ? '0'
                                                : ''))
                                          : '-'}
                                      </TableCell>
                                    )
                                    : (column.id === 'distributedAt'
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
                                        </TableCell>
                                      )
                                      : (column.id === 'caseNumber'
                                        ? (
                                          <TableCell key={column.id} align={column.align}>
                                            {value ? value.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'}
                                          </TableCell>
                                        )
                                        : (column.id === 'client'
                                          ? (
                                            <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                              {value}
                                            </TableCell>
                                          )
                                          : (
                                            <TableCell key={column.id} align={column.align}>
                                              {value}
                                            </TableCell>
                                          )
                                        ))))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.casesReceived.length > 0
          && (
            <>
              <h4 className='report2-subtitle'>
                <strong>CASOS RECEBIDOS</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList02.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.casesReceived
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList02.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : ((column.id === 'evaluationValue' || column.id === 'amountReceived')
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        R$
                                        {' '}
                                        {Number(value) > 0
                                          ? (Number(value).toLocaleString()
                                            + ((Number(value).toLocaleString().indexOf(',') === -1)
                                              ? ',00'
                                              : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1)
                                                ? '0'
                                                : ''))
                                          : '-'}
                                      </TableCell>
                                    )
                                    : ((column.id === 'distributedAt' || column.id === 'paymentReceivedAt')
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
                                        </TableCell>
                                      )
                                      : (column.id === 'caseNumber'
                                        ? (
                                          <TableCell key={column.id} align={column.align}>
                                            {value ? value.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'}
                                          </TableCell>
                                        )
                                        : (column.id === 'client'
                                          ? (
                                            <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                              {value}
                                            </TableCell>
                                          )
                                          : (
                                            <TableCell key={column.id} align={column.align}>
                                              {value}
                                            </TableCell>
                                          )
                                        ))))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
      </Paper>
    </>
  );
}

export default Report2;
